import React, {useEffect, useState} from 'react';
import {Col, Modal, notification, Row, Spin, Button} from "antd";
import '../modals.css';
import {approveOrderService} from "../../../services/approve-order.service";
import {fileHelper} from "../../../helpers/file.helper";

export default function ModalShowContractFiles({isShow, setIsShow, id}) {

  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState({});

  const textStyle = {
    textAlign: 'center',
    color: 'black'
  };

  useEffect(() => {
    setLoading(true);
    if (id && isShow) {
      approveOrderService
        .getContractsListOnUpload(id)
        .then(resp => {
          setInfo(resp);
          setLoading(false);
        })
        .catch(errMsg => notification.error(
          {
            message: "Получение документов",
            description: errMsg
          }));
    }
  }, [id, isShow]);

  const handleDownloadDoc = fileUrl => {
    window.open(fileUrl);
  };

  const handleDownloadDocsArchive = (id) => {
    approveOrderService.getDownloadContractOrderFiles(id)
      .then(resp => {
        fileHelper.downloadFileBase64(resp, 'contracts.zip')
      })
      .catch(err => {
        notification.error({
          message: "Загрузка архива",
          description: err
        });
      });
  };

  const renderButtonsDownload = info => {
    let buttons = [];

    Object.keys(info).forEach(value => {
      value = info[value];

      if(!(value.uploadedFile || value.blankFile)) {
        return;
      }

      buttons.push(
        <>
          <Button style={{marginLeft: "0px", width: "100%"}} onClick={() => handleDownloadDoc(value.uploadedFile)}
                  size="small" className="small-button typical-btn-green">
            Скачать "{value.alias}"
          </Button>
          <Button style={{marginLeft: "0px", width: "100%"}} onClick={() => handleDownloadDoc(value.blankFile)}
                  size="small" className="small-button typical-btn-blue">
            Скачать оригинал "{value.alias}"
          </Button>
        </>
      );
    });

    return (
      <>
        {buttons}
      </>
    );
  };

  let buttons = renderButtonsDownload(info);

  return (
    <Modal
      title={<span className="ant-modal-title">Документы</span>}
      cancelText="Закрыть"
      className="modal-window"
      style={{maxWidth: '450px'}}
      align={null}
      visible={isShow}
      onCancel={() => {
        setIsShow(false);
      }}
      footer={<Row>
        <Col offset={8} span={8}>
          <Button
            htmlType="button"
            key="submitPrev"
            onClick={() => handleDownloadDocsArchive(id)}
            type="primary"
            size="large"
            style={{width: '100%'}}
          >
            Скачать документы в архиве
          </Button>
        </Col>
      </Row>}
    >
      <Spin spinning={loading} tip="Загрузка...">
        <Row type="flex" justify="center">
          <Col lg={8} md={24} sm={24} xs={24}>
            {buttons}
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
}
