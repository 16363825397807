import React, { useState, useEffect } from 'react';
import { Button, Col, Modal, Row, Spin, Upload, Icon, DatePicker, notification } from "antd";
import { driverService } from "../../../services";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import InputField from '../../input-field';
import FieldBlock from '../../field-block';
import DividerBlock from '../../divider-block';
import SelectField from "../../select-field";
import moment from "moment";
import '../modals.css';
import { companyService } from "../../../services";
import PhoneField from "../../phone-field";

export default function ModalEditDriver({
    driverId,
    isShow,
    onCloseModal,
}) {
    const [loadingContentModal, setLoadingContentModal] = useState(true);
    const [loading, setLoading] = useState(false);
    const [fieldsErrors, setFieldsErrors] = useState([]);
    const [blockedFields, setBlockedFields] = useState([]);
    const [data, setData] = useState([]);

    let bigButtonStyle = { width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px' };
    let dividerStyle = { margin: '15px 0px 45px 0px', display: "block", clear: "both", width: "100%", minWidth: "100%", height: "1px" }

    useEffect(() => {
        if (isShow) {
            companyService.getCompanyDriversQuestionnaire(driverId).then((response) => {
                setData(response);

                setPartnerId(response.companyId);
                setFio(response.fio);
                setPhone(response.phone);
                setPhoneCountryID(parseInt(response.phoneCountryID));
                setAdditionalPhoneOne(response.additionalPhoneOne);
                setAdditionalPhoneTwo(response.additionalPhoneTwo);
                setSerialPassport(response.passportSerial);
                setNumberPassport(response.passportNumber);
                setDivisionPassport(response.passportDivision);
                setRegistrationPassport(response.registration);
                setDatePassport(moment(response.passportDate, 'YYYY-MM-DD'));
                setSerialDriverLicense(response.driverLicenseSerial);
                setDateDriverLicense(moment(response.driverLicenseDate, 'YYYY-MM-DD'));
                setNumberDriverLicense(response.driverLicenseNumber);
                setInn(response.inn);
                setScanHomePagePassport(response.scanPassportHomePage);
                setScanRegPagePassport(response.scanPassportRegPage);
                setScanDriverLicense1(response.scanDriverLicense1);
                setScanDriverLicense2(response.scanDriverLicense2);
                setRegCountry(response.regCountry);
                setLoading(true);
                setLoadingContentModal(false);

                setBlockedFields(response.blockedFields);
            })
        }
    }, [isShow]);



    const [partnerId, setPartnerId] = useState(null);
    const [fio, setFio] = useState(null);
    const [phone, setPhone] = useState(null);
    const [phoneCountryID, setPhoneCountryID] = useState(null);
    const [additionalPhoneOne, setAdditionalPhoneOne] = useState(null);
    const [additionalPhoneTwo, setAdditionalPhoneTwo] = useState(null);
    const [serialPassport, setSerialPassport] = useState(null);
    const [numberPassport, setNumberPassport] = useState(null);
    const [divisionPassport, setDivisionPassport] = useState(null);
    const [datePassport, setDatePassport] = useState(null);
    const [inn, setInn] = useState(null);
    const [registrationPassport, setRegistrationPassport] = useState(null);
    const [serialDriverLicense, setSerialDriverLicense] = useState(null);
    const [numberDriverLicense, setNumberDriverLicense] = useState(null);
    const [dateDriverLicense, setDateDriverLicense] = useState(null);
    const [scanHomePagePassport, setScanHomePagePassport] = useState(null);
    const [scanRegPagePassport, setScanRegPagePassport] = useState(null);
    const [scanDriverLicense1, setScanDriverLicense1] = useState(null);
    const [scanDriverLicense2, setScanDriverLicense2] = useState(null);
    const [regCountry, setRegCountry] = useState(null);
    const [defaultData, setDefaultData] = useState([]);
    const [previewImage, setPreviewImage] = useState(null);
    const [previewVisible, setPreviewVisible] = useState(false);


    const onClickEditDriver = () => {
        const driverData = {
            driverId: driverId,
            partnerId: partnerId,
            fio: fio,
            phone: phone,
            phoneCountryID: phoneCountryID,
            additionalPhoneOne: additionalPhoneOne,
            additionalPhoneTwo: additionalPhoneTwo,
            passportSerial: serialPassport,
            passportNumber: numberPassport,
            passportDivision: divisionPassport,
            passportDate: (datePassport)
                ? datePassport.format('YYYY-MM-DD')
                : null,
            inn: inn,
            registration: registrationPassport,
            driverLicenseSerial: serialDriverLicense,
            driverLicenseNumber: numberDriverLicense,
            driverLicenseDate: (dateDriverLicense)
                ? dateDriverLicense.format('YYYY-MM-DD')
                : null,
            scanPassportHomePage: scanHomePagePassport,
            scanPassportRegPage: scanRegPagePassport,
            scanDriverLicense1: scanDriverLicense1,
            scanDriverLicense2: scanDriverLicense2,
            regCountry: regCountry
        };

        driverService.saveEditingInfo(driverId, driverData)
            .then((response) => {
                setLoading(false);
                if (response.status === 'success') {
                    onCloseModal(true);
                    notification.success({
                        message: "Редактирование водителя",
                        description: response.message,
                    });
                } else if (response.status === 'error') {
                    setFieldsErrors(response.errors);
                }
            })
            .catch((error) => {
                notification.error({
                    message: "Редактирование водителя",
                    description: error,
                });
                setLoading(false);
            });
    };

    const getFieldErrors = (nameField) => {
        return (typeof fieldsErrors[nameField] !== "undefined")
            ? fieldsErrors[nameField].reduce((fieldErrors, error) => {
                return fieldErrors + '; ' + error;
            })
            : null;
    };

    const getFieldDisabled = (nameField) => {
        return blockedFields && blockedFields.find(item => item === nameField) !== undefined;
    }


    const uploaderPropsScanHomePagePassport = {
        multiple: false,
        showUploadList: true,
        onRemove: () => {
            setScanHomePagePassport(null);
        },
        beforeUpload: (file) => {
            setScanHomePagePassport(file)
            return false;
        },
        fileList: (scanHomePagePassport && scanHomePagePassport.length)
            ? [{
                uid: '-1',
                name: "",
                url: !(scanHomePagePassport instanceof File) ? scanHomePagePassport : URL.createObjectURL(scanHomePagePassport),
                status: 'done'
            }]
            : null,
        listType: 'picture'
    };
    const uploaderPropsScanRegPagePassport = {
        multiple: false,
        showUploadList: true,
        onRemove: () => {
            setScanRegPagePassport(null);
        },
        beforeUpload: (file) => {
            setScanRegPagePassport(file);
            return false;
        },
        fileList: (scanRegPagePassport && scanRegPagePassport.length)
            ? [{
                uid: '-2',
                name: "",
                url: !(scanRegPagePassport instanceof File) ? scanRegPagePassport : URL.createObjectURL(scanRegPagePassport),
                status: 'done'
            }] : null,
        listType: 'picture'
    };


    const uploaderPropsScanDriverLicense1 = {
        multiple: false,
        showUploadList: true,
        onRemove: () => {
            setScanDriverLicense1(null);
        },
        beforeUpload: (file) => {
            setScanDriverLicense1(file);
            return false;
        },
        fileList: (scanDriverLicense1 && scanDriverLicense1.length)
            ? [{
                uid: '-3',
                name: "",
                url: !(scanDriverLicense1 instanceof File) ? scanDriverLicense1 : URL.createObjectURL(scanDriverLicense1),
                status: 'done'
            }] : [],
        listType: 'picture'
    };
    const uploaderPropsScanDriverLicense2 = {
        multiple: false,
        showUploadList: true,
        onRemove: () => {
            setScanDriverLicense2(null);
        },
        beforeUpload: (file) => {
            setScanDriverLicense2(file);
            return false;
        },
        fileList: (scanDriverLicense2 && scanDriverLicense2.length)
            ? [{
                uid: '-4',
                name: "",
                url: !(scanDriverLicense2 instanceof File) ? scanDriverLicense2 : URL.createObjectURL(scanDriverLicense2),
                status: 'done'
            }] : null,
        listType: 'picture'
    };


    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }


    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };

    const handleCancel = () => setPreviewVisible(false);

    const passportHomePageDisabled = getFieldDisabled("scanPassportHomePage"),
        passportRegPageDisabled = getFieldDisabled("scanPassportRegPage"),
        scanDriverLicense1Disabled = getFieldDisabled("scanDriverLicense1"),
        scanDriverLicense2Disabled = getFieldDisabled("scanDriverLicense2");

    return (
        <Modal
            title={"Редактировать водителя"}
            cancelText="Закрыть"
            className="modal-window"
            align={null}
            visible={isShow}
            onCancel={onCloseModal}
            footer={[
                <Button htmlType="submit" key="submit" style={bigButtonStyle} type="primary" onClick={onClickEditDriver}>Сохранить</Button>,
            ]}
        >
            <Spin spinning={loadingContentModal} tip="Загрузка...">
                <Row gutter={[16, 24]}>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <InputField
                            name="fio" label="ФИО"
                            value={fio} setValue={setFio}
                            getFieldErrors={getFieldErrors}
                            getFieldDisabled={getFieldDisabled}
                            onInputChange={(e) => {
                                setDefaultData({
                                    ...defaultData,
                                    fio: e.target.value
                                })
                            }}
                        />
                    </Col>
                    {/*<Col lg={6} md={12} sm={12} xs={12}>*/}
                    {/*    <InputField*/}
                    {/*        name="phone" label="Телефон"*/}
                    {/*        value={phone} setValue={setPhone}*/}
                    {/*        getFieldErrors={getFieldErrors}*/}
                    {/*        getFieldDisabled={getFieldDisabled}*/}
                    {/*        onInputChange={(e) => {*/}
                    {/*            setDefaultData({*/}
                    {/*                ...defaultData,*/}
                    {/*                phone: e.target.value*/}
                    {/*            });*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*</Col>*/}
                    <Col lg={6} md={12} sm={12} xs={12}>
                        <SelectField
                            title="Страна регистрации"
                            name="regCountry"
                            selectedValue={regCountry}
                            values={[
                                { Id: 'Россия', Name: 'Россия' },
                                { Id: 'Беларусь', Name: 'Беларусь' },
                                { Id: 'Казахстан', Name: 'Казахстан' },
                                { Id: 'Другая страна', Name: 'Другая страна' }
                            ]}
                            onChange={(value) => {
                                setRegCountry(value);
                            }}
                            getErrors={getFieldErrors}
                            style={{ width: "100%" }}
                            getFieldDisabled={getFieldDisabled}

                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={24} sm={24} xs={24} span={12}>
                        <PhoneField
                            getErrors={getFieldErrors}
                            countryFieldName="phoneCountryID"
                            phoneFieldName="phone"
                            title="Моб. номер"
                            selectedCountryID={phoneCountryID}
                            onChangeCountry={(value) => {
                                setPhoneCountryID(value);
                            }}
                            phoneNumber={phone}
                            onChangePhone={(e) => {
                                setPhone(e.target.value);
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 24]}>
                    <Col lg={6} md={12} sm={12} xs={12}>
                        <InputField
                            name="additionalPhoneOne" label="Доп. номер"
                            value={additionalPhoneOne} setValue={setAdditionalPhoneOne}
                            getFieldErrors={getFieldErrors}
                            getFieldDisabled={getFieldDisabled}

                            onInputChange={(e) => {
                                setDefaultData({
                                    ...defaultData,
                                    appViber: e.target.value
                                })
                            }}
                        />
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12}>
                        <InputField
                            name="additionalPhoneTwo" label="Доп. номер"
                            value={additionalPhoneTwo} setValue={setAdditionalPhoneTwo}
                            getFieldErrors={getFieldErrors}
                            getFieldDisabled={getFieldDisabled}

                            onInputChange={(e) => {
                                setDefaultData({
                                    ...defaultData,
                                    appWhatsapp: e.target.value
                                })
                            }}
                        />
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12}>
                        <InputField
                            name="passportSerial" label="Серия паспорта"
                            value={serialPassport} setValue={setSerialPassport}
                            getFieldErrors={getFieldErrors}
                            getFieldDisabled={getFieldDisabled}
                            onInputChange={(e) => {
                                setDefaultData({
                                    ...defaultData,
                                    passportSerial: e.target.value
                                })
                            }}
                        />
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12}>
                        <InputField
                            name="passportNumber" label="Номер паспорта"
                            value={numberPassport} setValue={setNumberPassport}
                            getFieldErrors={getFieldErrors}
                            getFieldDisabled={getFieldDisabled}
                            onInputChange={(e) => {
                                setDefaultData({
                                    ...defaultData,
                                    passportNumber: e.target.value
                                })
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 24]}>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <InputField
                            name="passportDivision" label="Подразделение"
                            value={divisionPassport} setValue={setDivisionPassport}
                            getFieldErrors={getFieldErrors}
                            getFieldDisabled={getFieldDisabled}

                            onInputChange={(e) => {
                                setDefaultData({
                                    ...defaultData,
                                    passportDivision: e.target.value
                                })
                            }}
                        />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <FieldBlock title="Дата выдачи" name="passportDate" getErrors={getFieldErrors} getFieldDisabled={getFieldDisabled}>
                            <DatePicker
                                style={{ width: "100%" }}
                                defaultValue={datePassport}
                                value={datePassport}
                                format="DD.MM.YYYY"
                                size="normal"
                                name="passportDate"
                                locale={localeDatePicker}
                                onChange={(date) => {
                                    setDatePassport(date);
                                }}
                            />
                        </FieldBlock>
                    </Col>
                </Row>
                <Row gutter={[16, 24]}>
                    <Col span={24}>
                        <InputField
                            name="registration" label="Прописка (страна, город, улица, дом, кв)"
                            value={registrationPassport} setValue={setRegistrationPassport}
                            getFieldErrors={getFieldErrors}
                            getFieldDisabled={getFieldDisabled}

                            onInputChange={(e) => {
                                setDefaultData({
                                    ...defaultData,
                                    registration: e.target.value
                                })
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 24]}>
                    <Col lg={6} md={12} sm={12} xs={12}>
                        <InputField
                            name="driverLicenseSerial" label="Серия ВУ"
                            value={serialDriverLicense} setValue={setSerialDriverLicense}
                            getFieldErrors={getFieldErrors}
                            getFieldDisabled={getFieldDisabled}

                            onInputChange={(e) => {
                                setDefaultData({
                                    ...defaultData,
                                    driverLicenseSerial: e.target.value
                                })
                            }}
                        />
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12}>
                        <InputField
                            name="driverLicenseNumber" label="Номер ВУ"
                            value={numberDriverLicense} setValue={setNumberDriverLicense}
                            getFieldErrors={getFieldErrors}
                            getFieldDisabled={getFieldDisabled}

                            onInputChange={(e) => {
                                setDefaultData({
                                    ...defaultData,
                                    driverLicenseNumber: e.target.value
                                })
                            }}
                        />
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <FieldBlock title="Дата выдачи ВУ" name="driverLicenseDate" getErrors={getFieldErrors} getFieldDisabled={getFieldDisabled}>
                            <DatePicker
                                style={{ width: "100%" }}
                                defaultValue={dateDriverLicense}
                                value={dateDriverLicense}
                                format="DD.MM.YYYY"
                                size="normal"
                                name="driverLicenseDate"
                                locale={localeDatePicker}
                                allowClear={false}
                                onChange={(date) => {
                                    setDateDriverLicense(date);
                                }}
                            />
                        </FieldBlock>
                    </Col>
                </Row>
                {regCountry === 'Россия' ? <Row gutter={[16, 24]}>
                    <Col span={24}>
                        <InputField
                            name="inn" label="ИНН"
                            value={inn} setValue={setInn}
                            getFieldErrors={getFieldErrors}
                            getFieldDisabled={getFieldDisabled}

                            onInputChange={(e) => {
                                setDefaultData({
                                    ...defaultData,
                                    inn: e.target.value
                                })
                            }}
                        />
                    </Col>
                </Row> : null}

                <span className="divider-label">Загрузите сканы документов</span>
                <DividerBlock style={dividerStyle} />

                <Row gutter={[16, 24]}>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <FieldBlock name="scanPassportHomePage" getErrors={getFieldErrors}>
                            <Upload className={`single-btn-upload ${passportHomePageDisabled ? 'anticon-none' : ''}`} {...uploaderPropsScanHomePagePassport} disabled={passportHomePageDisabled} onPreview={handlePreview}>
                                <Button htmlType="button" disabled={passportHomePageDisabled}>
                                    <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="download-file-svg">
                                        <path d="M11.5631 0.165835C11.4647 0.0622087 11.3298 0 11.19 0H2.81953C1.27495 0 0 1.26988 0 2.81429V18.643C0 20.1875 1.27495 21.4574 2.81953 21.4574H14.1805C15.725 21.4574 17 20.1875 17 18.643V6.07435C17 5.93962 16.9378 5.81013 16.8497 5.71158L11.5631 0.165835ZM11.7134 1.82959L15.2534 5.54574H12.9522C12.268 5.54574 11.7134 4.99634 11.7134 4.31221V1.82959ZM14.1805 20.4208H2.81953C1.85038 20.4208 1.03659 19.6175 1.03659 18.643V2.81429C1.03659 1.84514 1.84514 1.03659 2.81953 1.03659H10.6768V4.31221C10.6768 5.57161 11.6928 6.58233 12.9522 6.58233H15.9634V18.643C15.9634 19.6175 15.1549 20.4208 14.1805 20.4208Z" fill="#1CAA53"></path>
                                        <path d="M12.7642 16.8447H4.23314C3.94813 16.8447 3.71484 17.0778 3.71484 17.363C3.71484 17.648 3.94813 17.8813 4.23314 17.8813H12.7695C13.0545 17.8813 13.2878 17.648 13.2878 17.363C13.2878 17.0778 13.0545 16.8447 12.7642 16.8447Z" fill="#1CAA53"></path>
                                        <path d="M5.84741 11.268L7.9828 8.97187V14.6317C7.9828 14.9167 8.21608 15.15 8.5011 15.15C8.78627 15.15 9.01939 14.9167 9.01939 14.6317V8.97187L11.1548 11.268C11.2584 11.3767 11.3933 11.4339 11.5331 11.4339C11.6575 11.4339 11.7872 11.3872 11.8856 11.2939C12.093 11.097 12.1085 10.7704 11.9116 10.5631L8.87435 7.30304C8.7758 7.19942 8.64107 7.13721 8.49602 7.13721C8.35081 7.13721 8.21608 7.19942 8.11769 7.30304L5.08044 10.5631C4.8835 10.7704 4.89906 11.102 5.10631 11.2939C5.32404 11.4908 5.65047 11.4753 5.84741 11.268Z" fill="#1CAA53"></path>
                                    </svg> Скан главной страницы паспорта
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <FieldBlock name="scanPassportRegPage" getErrors={getFieldErrors}>
                            <Upload className={`single-btn-upload ${passportRegPageDisabled ? 'anticon-none' : ''}`} {...uploaderPropsScanRegPagePassport} disabled={passportRegPageDisabled} onPreview={handlePreview}>
                                <Button htmlType="button" disabled={passportRegPageDisabled}>
                                    <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="download-file-svg">
                                        <path d="M11.5631 0.165835C11.4647 0.0622087 11.3298 0 11.19 0H2.81953C1.27495 0 0 1.26988 0 2.81429V18.643C0 20.1875 1.27495 21.4574 2.81953 21.4574H14.1805C15.725 21.4574 17 20.1875 17 18.643V6.07435C17 5.93962 16.9378 5.81013 16.8497 5.71158L11.5631 0.165835ZM11.7134 1.82959L15.2534 5.54574H12.9522C12.268 5.54574 11.7134 4.99634 11.7134 4.31221V1.82959ZM14.1805 20.4208H2.81953C1.85038 20.4208 1.03659 19.6175 1.03659 18.643V2.81429C1.03659 1.84514 1.84514 1.03659 2.81953 1.03659H10.6768V4.31221C10.6768 5.57161 11.6928 6.58233 12.9522 6.58233H15.9634V18.643C15.9634 19.6175 15.1549 20.4208 14.1805 20.4208Z" fill="#1CAA53"></path>
                                        <path d="M12.7642 16.8447H4.23314C3.94813 16.8447 3.71484 17.0778 3.71484 17.363C3.71484 17.648 3.94813 17.8813 4.23314 17.8813H12.7695C13.0545 17.8813 13.2878 17.648 13.2878 17.363C13.2878 17.0778 13.0545 16.8447 12.7642 16.8447Z" fill="#1CAA53"></path>
                                        <path d="M5.84741 11.268L7.9828 8.97187V14.6317C7.9828 14.9167 8.21608 15.15 8.5011 15.15C8.78627 15.15 9.01939 14.9167 9.01939 14.6317V8.97187L11.1548 11.268C11.2584 11.3767 11.3933 11.4339 11.5331 11.4339C11.6575 11.4339 11.7872 11.3872 11.8856 11.2939C12.093 11.097 12.1085 10.7704 11.9116 10.5631L8.87435 7.30304C8.7758 7.19942 8.64107 7.13721 8.49602 7.13721C8.35081 7.13721 8.21608 7.19942 8.11769 7.30304L5.08044 10.5631C4.8835 10.7704 4.89906 11.102 5.10631 11.2939C5.32404 11.4908 5.65047 11.4753 5.84741 11.268Z" fill="#1CAA53"></path>
                                    </svg> Скан страницы с пропиской
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                </Row>
                <Row gutter={[16, 24]}>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <FieldBlock name="scanDriverLicense1" getErrors={getFieldErrors}>
                            <Upload className={`single-btn-upload ${scanDriverLicense1Disabled ? 'anticon-none' : ''}`} {...uploaderPropsScanDriverLicense1} disabled={scanDriverLicense1Disabled} onPreview={handlePreview}>
                                <Button htmlType="button" disabled={scanDriverLicense1Disabled}>
                                    <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="download-file-svg">
                                        <path d="M11.5631 0.165835C11.4647 0.0622087 11.3298 0 11.19 0H2.81953C1.27495 0 0 1.26988 0 2.81429V18.643C0 20.1875 1.27495 21.4574 2.81953 21.4574H14.1805C15.725 21.4574 17 20.1875 17 18.643V6.07435C17 5.93962 16.9378 5.81013 16.8497 5.71158L11.5631 0.165835ZM11.7134 1.82959L15.2534 5.54574H12.9522C12.268 5.54574 11.7134 4.99634 11.7134 4.31221V1.82959ZM14.1805 20.4208H2.81953C1.85038 20.4208 1.03659 19.6175 1.03659 18.643V2.81429C1.03659 1.84514 1.84514 1.03659 2.81953 1.03659H10.6768V4.31221C10.6768 5.57161 11.6928 6.58233 12.9522 6.58233H15.9634V18.643C15.9634 19.6175 15.1549 20.4208 14.1805 20.4208Z" fill="#1CAA53"></path>
                                        <path d="M12.7642 16.8447H4.23314C3.94813 16.8447 3.71484 17.0778 3.71484 17.363C3.71484 17.648 3.94813 17.8813 4.23314 17.8813H12.7695C13.0545 17.8813 13.2878 17.648 13.2878 17.363C13.2878 17.0778 13.0545 16.8447 12.7642 16.8447Z" fill="#1CAA53"></path>
                                        <path d="M5.84741 11.268L7.9828 8.97187V14.6317C7.9828 14.9167 8.21608 15.15 8.5011 15.15C8.78627 15.15 9.01939 14.9167 9.01939 14.6317V8.97187L11.1548 11.268C11.2584 11.3767 11.3933 11.4339 11.5331 11.4339C11.6575 11.4339 11.7872 11.3872 11.8856 11.2939C12.093 11.097 12.1085 10.7704 11.9116 10.5631L8.87435 7.30304C8.7758 7.19942 8.64107 7.13721 8.49602 7.13721C8.35081 7.13721 8.21608 7.19942 8.11769 7.30304L5.08044 10.5631C4.8835 10.7704 4.89906 11.102 5.10631 11.2939C5.32404 11.4908 5.65047 11.4753 5.84741 11.268Z" fill="#1CAA53"></path>
                                    </svg> Скан 1 стороны ВУ
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <FieldBlock name="scanDriverLicense2" getErrors={getFieldErrors}>
                            <Upload className={`single-btn-upload ${scanDriverLicense2Disabled ? 'anticon-none' : ''}`} {...uploaderPropsScanDriverLicense2} disabled={scanDriverLicense2Disabled} onPreview={handlePreview}>
                                <Button htmlType="button" disabled={scanDriverLicense2Disabled}>
                                    <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="download-file-svg">
                                        <path d="M11.5631 0.165835C11.4647 0.0622087 11.3298 0 11.19 0H2.81953C1.27495 0 0 1.26988 0 2.81429V18.643C0 20.1875 1.27495 21.4574 2.81953 21.4574H14.1805C15.725 21.4574 17 20.1875 17 18.643V6.07435C17 5.93962 16.9378 5.81013 16.8497 5.71158L11.5631 0.165835ZM11.7134 1.82959L15.2534 5.54574H12.9522C12.268 5.54574 11.7134 4.99634 11.7134 4.31221V1.82959ZM14.1805 20.4208H2.81953C1.85038 20.4208 1.03659 19.6175 1.03659 18.643V2.81429C1.03659 1.84514 1.84514 1.03659 2.81953 1.03659H10.6768V4.31221C10.6768 5.57161 11.6928 6.58233 12.9522 6.58233H15.9634V18.643C15.9634 19.6175 15.1549 20.4208 14.1805 20.4208Z" fill="#1CAA53"></path>
                                        <path d="M12.7642 16.8447H4.23314C3.94813 16.8447 3.71484 17.0778 3.71484 17.363C3.71484 17.648 3.94813 17.8813 4.23314 17.8813H12.7695C13.0545 17.8813 13.2878 17.648 13.2878 17.363C13.2878 17.0778 13.0545 16.8447 12.7642 16.8447Z" fill="#1CAA53"></path>
                                        <path d="M5.84741 11.268L7.9828 8.97187V14.6317C7.9828 14.9167 8.21608 15.15 8.5011 15.15C8.78627 15.15 9.01939 14.9167 9.01939 14.6317V8.97187L11.1548 11.268C11.2584 11.3767 11.3933 11.4339 11.5331 11.4339C11.6575 11.4339 11.7872 11.3872 11.8856 11.2939C12.093 11.097 12.1085 10.7704 11.9116 10.5631L8.87435 7.30304C8.7758 7.19942 8.64107 7.13721 8.49602 7.13721C8.35081 7.13721 8.21608 7.19942 8.11769 7.30304L5.08044 10.5631C4.8835 10.7704 4.89906 11.102 5.10631 11.2939C5.32404 11.4908 5.65047 11.4753 5.84741 11.268Z" fill="#1CAA53"></path>
                                    </svg> Скан 2 стороны ВУ
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                </Row>
                <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </Spin>
        </Modal>);
}