import {Modal, Button} from "antd";
import React, { useEffect, useState } from "react";
import { useInView, InView } from "react-intersection-observer";
import { termsConfig } from "../../../config/terms.config";
import DividerBlock from "../../divider-block";

export default function ShowAllTerms({  isShow, setIsShow, setIsAllTermsAgreed }) {
    const [endPage, inViewEndPage] = useInView({
        threshold: 0.2,
    })

    const [modalTitle, setModalTitle] = useState('Пользовательские соглашения');

    const setInView = (inView, entry) => {
        if (inView) {
            console.log(entry.target);
            setModalTitle(entry.target.getAttribute("title"));
        }
    };

    const submitBtnStyle = {
        width: "200px",
        height: "43px",
        background: "#1CAA53",
        position: "static",
        borderRadius: "10px",
        color: "white",
        borderColor: "transparent",
    }

    return (
        <Modal
            title={modalTitle}
            cancelText="Закрыть"
            className="modal-window modal-window--terms"
            apgn={null}
            visible={isShow}
            onCancel={() => {
                setIsShow(false);
            }}
            footer={
                <Button htmlType="submit" key="submit" type="primary"
                        style={submitBtnStyle}
                        onClick={() => {
                            setIsAllTermsAgreed(true);
                            setIsShow(false);
                        }}
                        disabled={!inViewEndPage}
                >Принять</Button>
            }
        >
            <DividerBlock label={termsConfig.terms_of_use_title} />
            <InView onChange={setInView} threshold={0.1} key={1}>
            {({ ref }) => {
                return (
                    <div ref={ref} title={termsConfig.terms_of_use_title}>{termsConfig.terms_of_use_text}</div>
                );
            }}
            </InView>

            <DividerBlock label={termsConfig.terms_title} />
            <InView onChange={setInView} threshold={0.1} key={2}>
            {({ ref }) => {
                return (
                    <div ref={ref} title={termsConfig.terms_title}>{termsConfig.terms_text}</div>
                );
            }}
            </InView>

            <DividerBlock label={termsConfig.offer_title} />
            <InView onChange={setInView} threshold={0.1} key={3}>
            {({ ref }) => {
                return (
                    <div ref={ref} title={termsConfig.offer_title}>{termsConfig.offer_text}</div>
                );
            }}
            </InView>
            <div><p ref={endPage}>&nbsp;</p></div>
        </Modal>
    );
}
