import React, {useEffect, useState} from "react";
import {notification, Pagination} from "antd";
import Spinner from "../../../spinner";
import {companyService, userServices, distributionCenterService} from "../../../../services";
import CarrierCompanyCard from "./carrier-company-card";
import {companiesConfig} from "../../../../config";
import FiltersForConsignor from "./filters";
import { LAWYER_LEGAL_ENTITY_APPROVE } from "../../../../helpers/user_permission.helper";


export default function CompaniesList({consignorSettings}) {
  const user = userServices.getUser();

  const [loading, setLoading] = useState(true);
  const [isNeedUpdate, setIsNeedUpdate] = useState(true);
  const [stopListUpdate, setStopListUpdate] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [step, setStep] = useState(companiesConfig.pageCount);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [listCompanies, setListCompanies] = useState([]);
  const [shipperCompanies, setShipperCompanies] = useState([]);
  const [distributionCentersList, setDistributionCentersList] = useState([]);

  const [counts, setCounts] = useState({
    approveOrdersCount: 0,
    contractOrdersCount: 0
  })

  let initLegalEntityApproveStatus = (user.isAccessTo(LAWYER_LEGAL_ENTITY_APPROVE)) ? [1] : [];

  const [filterParams, setFilterParams] = useState({
    legalNumber: null,
    username: null,
    name: null,
    webid: null,
    legalEntityApproveStatuses: initLegalEntityApproveStatus,
    contractApproveStatuses: [],
    agreedContractByShipperCompanies: [],
    distributionCenterId: [],
    haveDelivDelay: false,
    minDelivDelay: null,
    maxDelivDelay: null,
    haveAccreditation: false,
    consignorID: null,
    limit: step,
    offset: offset,
  });

  useEffect(() => {
    if (isNeedUpdate) {
      getNewOrdersCount();
      getShipperCompanyList();
      getDistributionCentersList();
      getList();
    }
  }, [isNeedUpdate]);

  const getList = () => {
    setLoading(true);
    companyService.getListForConsignor(filterParams)
      .then(resp => {
        setListCompanies(resp.data);
        setIsNeedUpdate(false);
        setLoading(false);
        setTotalCount(resp.totalCount);
      })
      .catch((err) => {
        notification.error({
          message: "Получение настроек уведомлений по РЦ",
          description: err,
        });
      });
  };

  const getShipperCompanyList = () => {
    companyService.getShipperCompanyList()
      .then(resp => {
        setShipperCompanies(resp);
      })
      .catch((err) => {
        notification.error({
          message: "Получение компаний грузоотправителя",
          description: err,
        });
      });
  };

  const getDistributionCentersList = () => {
    distributionCenterService.getCompanies()
      .then((response) => {
          setDistributionCentersList(response);
      })
      .catch((err) => {
          notification.error({
              message: "Получение списка РЦ",
              description: err,
          });
      });
  };

  const getNewOrdersCount = () => {
    companyService.getNewOrdersCount()
      .then(resp => {
        setCounts(resp);
      })
      .catch((err) => {
        notification.error({
          message: "Получение количества заявок",
          description: err,
        });
      });
  }

  let onChange = (page, pageSize) => {
    if (page === currentPage) {
      return false;
    } else {
      setCurrentPage(page);
    }

    let val = 0;
    if (parseInt(page) === 1) {
      setOffset(0);
      setFilterParams({...filterParams, offset: 0})
    } else {
      val = page * pageSize - pageSize;
      setFilterParams({...filterParams, offset: val})
      setOffset(val);
    }

    setIsNeedUpdate(true);
  };

  return (
    <>
      <h1 className="content-header">Компании перевозчиков</h1>
      <FiltersForConsignor filterParams={filterParams} setCurrentPage={setCurrentPage} counts={counts} isNeedUpdate={isNeedUpdate} shipperCompanies={shipperCompanies} distributionCentersList={distributionCentersList} setFilterParams={setFilterParams} setIsNeedUpdate={setIsNeedUpdate} userRole={user.info.role}/>
      <Spinner loading={loading} tip="Загрузка...">
        {listCompanies && listCompanies.length !== 0 ?
          <div className="application-block pagination">
            <Pagination
              style={{textAlign: "center", marginBottom: "20px"}}
              current={currentPage}
              pageSize={step}
              total={totalCount}
              showTotal={(total, range) => `${range[0]}-${range[1]} из ${totalCount}`}
              onChange={onChange}
            />
          </div>
          : null}
        {listCompanies.map((item) =>
          <CarrierCompanyCard company={item} key={item.legalEntityID} consignorSettings={consignorSettings} setIsNeedUpdate={setIsNeedUpdate} setStopListUpdate={setStopListUpdate}/>
        )}
        {listCompanies && listCompanies.length !== 0 ?
          <div className="application-block pagination">
            <Pagination
              style={{textAlign: "center", paddingBottom: 0}}
              current={currentPage}
              pageSize={step}
              total={totalCount}
              showTotal={(total, range) => `${range[0]}-${range[1]} из ${totalCount}`}
              onChange={onChange}
            />
          </div>
          : null}
      </Spinner>
    </>
  );
}
