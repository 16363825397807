import { httpRequest } from "./http-request";
import {stringsHelper} from "../helpers/strings.helper";

export const consignorService = {
    getStaffUsersList,
    getShipperCompaniesList,
    createStaffUser,
    createShipperCompany,
    getConsignorsList,
    createConsignor,
    getOrdersList,
    approveOrder,
    downloadOrderBlanks,
    agreeOrder,
    getOrderInfo,
    refuseOrder,
    refuseOrderToConsignor,
    getLogistsList,
    getSettings,
    geShipperCompanyInfo,
    getShipperCompanyAddPramsOnContractList,
    removeShipperCompanyAddParam,
    createShipperCompanyAddParam,
    agreeApproveOrder,
    approveOrderBecomeCarrier,
    getStaffUserInfo,
    editStaffUser,
    getInfo,
    getActionsToApproveList,
    approveAction,
    declineAction,
};

async function getConsignorsList() {
  return await httpRequest.get(`consignor/get-list`).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getStaffUsersList() {
  return await httpRequest.get(`consignor/get-staff-users-list`).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getShipperCompaniesList() {
  return await httpRequest.get(`shipper-company/get-list`).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function createStaffUser({ phone, email, fio, password, passwordRepeat, role, distributionCenters, notifyDistributionCenters }) {
  const formData = new FormData();

  formData.append("fio", fio);
  formData.append("email", email);
  formData.append("phone", phone);
  formData.append("role", role);
  formData.append("password", password);
  formData.append("passwordRepeat", passwordRepeat);
  formData.append("distributionCenters", distributionCenters);
  formData.append("notifyDistributionCenters", notifyDistributionCenters);

  return await httpRequest
    .post("consignor/create-staff-user", formData, {
      headers: {
        "Content-Type": "form-data",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function createShipperCompany(data) {
  const formData = new FormData();

  formData.append('name', data.name);
  formData.append('fullName', data.fullName);

  return await httpRequest
      .post("shipper-company/create-shipper", formData, {
        headers: {
          "Content-Type": "form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response.data);
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
      });
}

async function createConsignor(data) {
  const formData = new FormData();

  formData.append('consignorName', data.consignorName);
  formData.append('fio', data.fio);
  formData.append('phone', data.phone);
  formData.append('email', data.email);

  return await httpRequest
      .post("consignor/create-consignor", formData, {
        headers: {
          "Content-Type": "form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response.data);
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
      });
}

async function getOrdersList(listType = 1, legalNumber = "", legalEntityName = "", webid ="", limit = 50, offset = 0, agreedContractByShipperCompanies = '') {
  const addLimit = limit ? `?limit=${limit}` : '?limit=50';
  const addOffset = offset? `&offset=${offset}` : '&offset=0';
  const addListType = listType ? `&listType=${listType}` : '&listType=1';
  const addLegalNumber = legalNumber ? `&legalNumber=${legalNumber}` : '';
  const addLegalEntityName = legalEntityName ? `&legalEntityName=${legalEntityName}` : '';
  const addWebId = webid ? `&webid=${webid}` : '';
  const addAgreedContractByShipperCompanies = agreedContractByShipperCompanies && Boolean(agreedContractByShipperCompanies.length) ?
    agreedContractByShipperCompanies.map((item,index)=>{return `&agreedContractByShipperCompanies[${index}]=${item}`}).join('')
    : '';

  return await httpRequest.get(`approve-order/get-list-orders-to-shipper-companies` + addLimit + addOffset + addListType + addLegalNumber + addLegalEntityName + addWebId + addAgreedContractByShipperCompanies)
    .then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function approveOrder(orderId, approveType, addFiles, customDocs, carrierType = null, comment = null) {
    const formData = new FormData();

    if (addFiles) {
        addFiles.forEach(value => {
            formData.append(value.name, value.value ? value.value : '');
        });
    }

    if (customDocs) {
        customDocs.forEach(value => {
            formData.append('customDocs[]', value);
        });
    }

    if (comment) {
      formData.append('comment', comment);
    }

    return await httpRequest
      .post(`approve-order/approve?orderID=${orderId}&approveType=${approveType}${carrierType ? '&carrierType='+carrierType : ''}`, formData)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response.data);
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
      });
}

async function approveOrderBecomeCarrier(orderId, carrierType) {
    return await httpRequest
        .get(`approve-order/approve-order-become-carrier?orderID=${orderId}&companyType=${carrierType}`)
        .then((response) => {
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }
            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function agreeApproveOrder(orderId) {
    return await httpRequest
        .get(`approve-order/approve-by-personal-responsibility?orderID=${orderId}`)
        .then((response) => {
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }
            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function downloadOrderBlanks(orderId) {
  const formData = new FormData();

  formData.append('orderId', orderId);

  return await httpRequest
      .post('approve-order/get-generated-contract-blank', formData, {
        headers: {
          "Content-Type": "form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response.data);
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
      });
}

async function agreeOrder(orderId, guarantorContract, organizationContract, isNeedContracts = 1) {
  const formData = new FormData();
  formData.append('OrderId', orderId);
  formData.append('GuarantorContract', guarantorContract);
  formData.append('OrganizationContract', organizationContract);
  formData.append('IsNeedContracts', isNeedContracts);

  return await httpRequest
      .post(`approve-order/agree-order-to-shipper-company`, formData)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response.data);
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
      });
}

async function getOrderInfo(orderId) {
    const formData = new FormData();
    formData.append('orderId', orderId);
    return await httpRequest.post('approve-order/get-info', formData).then((response) => {
        if (response.status === 200) {
            return Promise.resolve(response.data);
        }
        return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function refuseOrder(legalEntityApproveOrderId, refuseReason) {
    return await httpRequest.get('approve-order/refuse-order-to-shipper-company?OrderId='+ legalEntityApproveOrderId +'&RefuseReason='+ refuseReason).then((response) => {
        if (response.status === 200) {
            return Promise.resolve(response.data);
        }
        return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function refuseOrderToConsignor(legalEntityApproveOrderId, refuseReason) {
    return await httpRequest.post('approve-order/refuse-order', {
        orderId: legalEntityApproveOrderId,
        refuseReason: refuseReason
    }).then((response) => {
        if (response.status === 200) {
            return Promise.resolve(response.data);
        }
        return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getLogistsList() {
  return await httpRequest.get('consignor/get-logists-list').then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getSettings(consignorId = null) {
    const formData = new FormData();
    formData.append('consignorId', consignorId);

    return await httpRequest.post('consignor/get-settings', formData).then((response) => {
        if (response.status === 200) {
            return Promise.resolve(response.data);
        }
        return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function geShipperCompanyInfo(shipperCompanyId) {
    return await httpRequest.get('shipper-company/info?Id='+ shipperCompanyId)
        .then((response) => {
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }
            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function getShipperCompanyAddPramsOnContractList(shipperCompanyId) {
    return await httpRequest.get('shipper-company/get-add-params-on-contract-create?id='+ shipperCompanyId)
        .then((response) => {
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }
            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function removeShipperCompanyAddParam(shipperCompanyID, paramName) {
    return await httpRequest.get('shipper-company/remove-add-param-on-contract-create?shipperCompanyID='+ shipperCompanyID +'&paramName='+ paramName)
        .then((response) => {
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }
            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}


async function createShipperCompanyAddParam(shipperCompanyID, paramName) {
    const formData = new FormData();

    formData.append('Alias', paramName);

    return await httpRequest
        .post("shipper-company/create-add-param-on-contract-create?id="+ shipperCompanyID, formData, {
            headers: {
                "Content-Type": "form-data",
            },
        })
        .then((response) => {
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function getStaffUserInfo(userID) {
    return await httpRequest.get(`consignor/get-staff-user-info?UserID=${userID}`)
        .then((response) => {
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }
            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function editStaffUser({ userID, phone, email, fio, role, distributionCenters, notifyDistributionCenters, blocked }) {
    const formData = new FormData();

    formData.append("userID", userID);
    formData.append("fio", fio);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("role", role);
    formData.append("distributionCenters", distributionCenters);
    formData.append("notifyDistributionCenters", notifyDistributionCenters);
    formData.append("blocked", blocked);

    return await httpRequest
        .post("consignor/edit-staff-user", formData, {
            headers: {
                "Content-Type": "form-data",
            },
        })
        .then((response) => {
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }

            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function getInfo() {
    return await httpRequest.get(`consignor/info`)
        .then((response) => {
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }
            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function getActionsToApproveList() {
    return await httpRequest.get(`consignor/get-list-actions-to-approve`)
        .then((response) => {
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }
            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function approveAction(id) {
    return await httpRequest.get(`consignor/approve-action?id=${id}`)
        .then((response) => {
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }
            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}

async function declineAction(id) {
    return await httpRequest.get(`consignor/decline-approve-action?id=${id}`)
        .then((response) => {
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }
            return Promise.reject(httpRequest.getDefaultMessageError());
        });
}
