import {Modal, Button} from "antd";
import React from "react";
import { useInView } from "react-intersection-observer";
import { termsConfig } from "../../../config/terms.config";

export default function ShowTerms({  isShow, setIsShow, setIsTermAgreed }) {
    const [endPage, inViewEndPage] = useInView({
        threshold: 0.2,
    })

    const submitBtnStyle = {
        width: "200px",
        height: "43px",
        background: "#1CAA53",
        position: "static",
        borderRadius: "10px",
        color: "white",
        borderColor: "transparent",
    }

    return (
        <Modal
            title={termsConfig.terms_title}
            cancelText="Закрыть"
            className="modal-window modal-window--terms"
            apgn={null}
            visible={isShow}
            onCancel={() => {
                setIsShow(false);
            }}
            footer={
                <Button htmlType="submit" key="submit" type="primary"
                        style={submitBtnStyle}
                        onClick={() => {
                            setIsTermAgreed(true);
                            setIsShow(false);
                        }}
                        disabled={!inViewEndPage}
                >Принять</Button>
            }
        >
            {termsConfig.terms_text}
            <div><p ref={endPage}>&nbsp;</p></div>
        </Modal>
    );
}
