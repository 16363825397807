/** @var string Просмотр свободных рейсов логиста */
export const VIEW_FREE_DELIVERIES_LOGIST = "view_free_deliveries_logist";

/** @var string Просмотр зарезервированных рейсов логиста */
export const VIEW_RESERVED_DELIVERIES_LOGIST = "view_reserved_deliveries_logist";

/** @var string Просмотр забронированных рейсов логиста */
export const VIEW_BOOKED_DELIVERIES_LOGIST = "view_booked_deliveries_logist";

/** @var string Просмотр согласованных рейсов логиста (Архив) */
export const VIEW_AGREED_DELIVERIES_LOGIST = "view_agreed_deliveries_logist";

/** @var string Просмотр рейсов логиста на погрузке */
export const VIEW_ONLOADING_DELIVERIES_LOGIST = "view_onloading_deliveries_logist";

/** @var string Просмотр свободных рейсов перевозчика */
export const VIEW_FREE_DELIVERIES_CARRIER = "view_free_deliveries_carrier";

/** @var string Просмотр забронированных рейсов перевозчика */
export const VIEW_BOOKED_DELIVERIES_CARRIER = "view_booked_deliveries_carrier";

/** @var string Просмотр согласованных рейсов перевозчика (Архив) */
export const VIEW_AGREED_DELIVERIES_CARRIER = "view_agreed_deliveries_carrier";

/** @var string Просмотр отчета "Статистика по заявкам" */
export const VIEW_REPORT_STATS_ORDER_DELIVERIES = "view_report_stats_order_deliveries";

/** @var string Управление служебной информацией */
export const SERVICE_INFORMATION_MANAGEMENT = "service_information_management";

/** @var string Страница рейсов для водительского аккаунта */
export const VIEW_NEW_DELIVERIES_DRIVER = "view_new_deliveries_driver";

/** @var string Страница директора компании */
export const COMPANY_DIRECTOR_PERMISSION = "company_director_permission";

/** @var string Страница директора грузоотправителя */
export const CONSIGNOR_DIRECTOR_PERMISSION = "consignor_director_permission";

/** @var string Подтверждение юр. лиц под личную отвественность */
export const PERSONAL_RESPONSIBILITY_LEGAL_ENTITY_APPROVE = 'personal_responsibility_legal_entity_approve';

/** @var string Подтверждение юр. лиц логистом */
export const LOGIST_LEGAL_ENTITY_APPROVE = 'logist_legal_entity_approve';

/** @var string Подтверждение юр. лиц бухгалтером */
export const ACCOUNTANT_LEGAL_ENTITY_APPROVE = 'accountant_legal_entity_approve';

/** @var Подтверждение юр. лиц юристом */
export const LAWYER_LEGAL_ENTITY_APPROVE = 'lawyer_legal_entity_approve';

/** @var Возможность создавать рейс на сайте */
export const CREATE_DELIVERY = 'create_delivery';

/** @var Сменить интерфейс с отправителя на перевозчика и обратно */
export const SWITCH_TO_DIFFERENT_COMPANY_TYPE = 'switch_to_different_company_type';

/** @var Согласовать грузоотправителя на перевозку грузов */
export const APPROVE_BECOME_CARRIER_ORDER = 'approve_become_carrier_order';

/** @var Редактировать данные компании перевозчика */
export const EDIT_COMPANY_INFO = 'edit_company_info';

/** @var string Контроль исполнительской дисциплины */
export const PERFORMANCE_DISCIPLINE_CONTROL = 'performance_discipline_control';

/** @var string Доступ перевозчика/сотрудника к вкладке 'Моя компания' */
export const VIEW_COMPANY_PAGE = 'view_company_page';

/** @var string Улучшенная защита аккаунта */
export const ADVANCED_ACCOUNT_PROTECTION = 'advanced_account_protection';

/** @var string Может загружать доп. параметры для погрузки рейса */
export const UPLOAD_ADD_PARAMS_ON_LOADING_DELIVERY = 'upload_add_params_on_loading_delivery';

/** @var string Отправить рейс в путь */
export const EN_ROUTE_DELIVERY = 'en_route_delivery';

/** @var string Отмена доставки */
export const REFUSE_DELIVERY = 'refuse_delivery';

/** @var array Откат согласования */
export const APPROVE_ROLLBACK = ['access_request_approve_rollback','process_approve_rollback'];

/** @var array Изменить тип компании */
export const CHANGING_PARTNER_TYPE = ['access_request_changing_partner_type','process_changing_partner_type'];

/** @var array Редактировать реквизиты компании */
export const CHANGING_REQUISITES = ['access_request_changing_requisites','process_changing_requisites'];

/** @var array Изменить логин */
export const CHANGING_LOGIN = ['access_request_changing_login','process_changing_login'];

